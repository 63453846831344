<template>
  <div>
    <p>its aliveee!!!</p>
    <p>
      well, technically its dead and you're lost, go back
      <a href="/">home</a> =P
    </p>
  </div>
</template>

<script>
export default {
  name: "404"
};
</script>
